import React from "react";
import Marquee from "react-fast-marquee";
import { Image } from "react-datocms";

export const SocialProofWrapper = ({ children, dark = false, transparent = false }) => {
    return (
        <>
            <div className="social-proof">
                <div className="social-proof-content">
                    {children}
                </div>
            </div>
            <style jsx>{`
            .social-proof {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100vw;
                background: ${transparent ? "transparent;" : !dark ? "" : "var(--covalent-dark-blue);"}
                background-image: ${!dark ? "" : ""};
                position: relative;
                margin-bottom: ${transparent ? ";" : !dark ? "-73px;" : ";"}
            }
            .social-proof-content {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 100%;
                width:100%;
                gap:3rem;
                padding: 1rem var(--landing-page-padding);
                max-width: var(--landing-page-max-width);
                z-index:100;
                
            }
            @media (max-width: 768px) {
                .social-proof {
                    flex-direction: column;
                    height: auto;
                }
                .social-proof-content {
                    width: 100%;
                    height: auto;
                    padding: 0 2rem;
                    margin-bottom: 2rem;
                }
            }
        `}</style></>

    );
};

export const SocialProofWrapperComponent = ({ children }) => {
    return (
        <>
            <div className="social-proof">
                <div className="social-proof-content">
                    {children}
                </div>
            </div>
            <style jsx>{`
            .social-proof {
                display: flex;
                flex-direction: row;
                width: inherit;
                height: max-content;
                display: contents;
            }
            .social-proof-content {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 100%;
                width:100%;
                gap:3rem;
                max-width: var(--landing-page-max-width);
                z-index:100;
                
            }
            @media (max-width: 768px) {
                .social-proof {
                    flex-direction: column;
                    height: auto;
                    display: none;
                }
                .social-proof-content {
                    width: 100%;
                    height: auto;
                    gap:4rem;
                    padding: var(--landing-page-padding);

                }
            }
        `}</style></>

    );
};

export const SocialProofTitle = ({ children }) => {
    return <>
        <h2>
            {children}
        </h2>
        <style jsx>
            {`
            h2 {
                font-size: var(--font-size-7);
                margin-bottom: 1rem;
                line-height: 1.2;
            }
        `}
        </style>
    </>;
};

export const SocialProofSubtitle = ({ children }) => {
    return <>
        <p>
            {children}
        </p>
        <style jsx>
            {`
            p {
                font-size: var(--font-size-5);
                margin-bottom: 1rem;
            }
        `}
        </style>
    </>;
};

export const SocialProofTicker = ({ ecosystem }) => {
    return (
        <>
            <div className="social-proof">
                <div className="first-row">
                    <Marquee gradientWidth={140} direction="left">
                        {ecosystem.map((o) => {
                            return <Image key={o.src} className="network" data={o}  usePlaceholder={false}/>;
                        })}
                    </Marquee>
                </div>
            </div>
            <style jsx>{`
            .first-row{
                display:flex;
                flex-wrap:wrap;
                gap: var(--landing-page-gap);
                background:white;
                padding:0.5rem;
                border-radius: calc(var(--border-radius) * 1);
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
                margin-top: -75px;

            }
            :global(.network) {
                width: 100px !important;
                height:100px;
                margin-left:20px;
                border-radius: calc(var(--border-radius) * 1);
            }
            @media (max-width: 768px) {
                .first-row{
                    margin-top: -4rem;
                    margin-left: -2rem;
                    margin-right: -2rem;
                }
                :global(.network) {
                    width: 50px !important;
                    height: 50px;
                    margin-left:20px;
                    border-radius: calc(var(--border-radius) * 1);
                }
            }
        
        `}</style></>

    );
};