import { Image } from "react-datocms";
import React from "react";

export const OurApproachWrapper = ({ children, dark = false, glass = true}) => {
    return (
        <><div className={"ourapproach " + (dark && "dark")}>
            <div className="ourapproach__content">
                {children}
            </div>
        </div>
        <style jsx>{`
            .ourapproach {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100vw;
                position: relative;
                background-color: ${glass ? "var(--background)" : "var(--covalent-dark-blue)"};
            }

            }
            .ourapproach::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 1;
            }
            .ourapproach.dark {
                color: #fff;
            }
            .ourapproach:not(.dark) {
                background: var(--background);
            }
            .ourapproach__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                min-height: max-content;
                padding: var(--landing-page-padding);
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
            }
            @media (max-width: 768px) {
                .ourapproach {
                    flex-direction: column;
                    height: auto;
                    border: none;
                }
                .ourapproach__content {
                    width: 100%;
                    height: auto;
                    padding: var(--landing-page-padding);
                }
            }
        `}</style></>

    );
};

export const OurApproachTitle = ({ children }) => {

    return (
        <h2 className={"ourapproach__title animate-in"}>{children}
            <style jsx>{`
            .ourapproach__title {
                font-size: var(--font-size-7);
                margin-left: -1px;
                max-width: 50rem;
                line-height: 1.2;
                font-weight: var(--font-weight-bold);
                z-index: 1;
            }
            .gradient-text {
                background: linear-gradient(90deg, var(--pink), var(--teal));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            @media (max-width: 768px) {
                .ourapproach__title {
                    font-size: var(--font-size-5);
                }
            }
        `}</style></h2>
    );
};

export const OurApproachSubtitle = ({ children }) => {
    return (
        <p className={"ourapproach__subtitle animate-in"}>{children}
            <style jsx>{`
            .ourapproach__subtitle {
                font-size: var(--font-size-4);
                line-height: var(--line-height-4);
                margin-bottom: 0rem;
                margin-top: 1rem;
                max-width: 45rem;
                color: var(--text);
            }
            @media (max-width: 768px) {
                .ourapproach__subtitle {
                    max-width: 100%;
                }
            }
        `}</style></p>
    );
};

export const OurApproachBadge = ({ children }) => {
    return (
        <div className="ourapproach__badge animate-in">{children}
            <style jsx>{`
            .ourapproach__badge {
                font-size: var(--font-size-5);
                margin-bottom: 0.6rem;

            }
        `}</style></div>
    );
};

export const OurApproachCtas = ({ children }) => {
    return (
        <div className="ourapproach__ctas animate-in">
            {children}
            <style jsx>{`
            :global(.ourapproach__cta:nth-child(2) button) {
                background-color: white;
                color: var(--pink);
                border: 1px solid var(--pink);
            }
            :global(.ourapproach__cta:nth-child(2) button):hover {
                background-color: var(--pink);
                color: #fff;
                border: 1px solid var(--pink);
            }
            .ourapproach__ctas {
                gap: 1rem;
                display: flex;
                margin-top: 1rem;
            }
        `}</style></div>
    );
};

export const OurApproachImage = ({ src, backdrop = false }) => {
    return (<>
        <Image  usePlaceholder={false} className="ourapproach__image animate-in" data={src} />
        <style jsx>
            {`
                :global(.ourapproach__image) {
                    border-radius: calc(var(--border-radius) * 4);
                    overflow: hidden;
                    box-shadow: 0 20px 25px -5px rgb(0 0 0/.1),0 8px 10px -6px rgb(0 0 0/.1);
                    border: 3px solid var(--background);
                    margin-top: 1rem;
                    background: ${backdrop ? "var(--covalent-dark-blue)" : ""};
                }
            `}
        </style>
    </>);
};

export const OurApproachCTA = ({ children }) => {
    return (
        <div className="ourapproach__cta animate-in">{children}</div>
    );
};