export const NumberWrapper = ({ children, dark = false}) => {
    return (
        <>
            <div className={"number " + (dark && "dark")}>
                <div className="number__content">
                    {children}
                </div>
            </div>
            <style jsx>{`
            :global(.dark .number) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                min-height: max-content;
                width: 100vw;
                position: relative;
                -webkit-backdrop-filter: blur(30px);
                backdrop-filter: blur(30px);
            }
            :global(.number:not(.dark .number)) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                min-height: max-content;
                width: 100vw;
                position: relative;
                overflow: hidden;
                background: var(--white);
            }
            .number__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                padding: var(--landing-page-padding);
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
            }
            @media (max-width: 768px) {
                .number {
                    flex-direction: column;
                    height: auto;
                }
                .number__content {
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin-top: 3rem;
                    margin-bottom: 2rem;
                }
            }
        `}</style></>

    );
};

export const NumberTitle = ({ children }) => {
    return (
        <h2 className="number__title">{children}</h2>
    );
};

export const NumberCard = ({ children }) => {
    return (
        <div className={" animate-in number__card "}>{children}
            <style jsx>{`
            .number__card {
                display: flex;
                flex-direction: column;
                align-items: start;
                width: 100%;
                min-width: 10rem;
                height: 100%;
                padding: 2rem;
                border-radius: calc(var(--border-radius) * 4);
                height: auto;
                z-index: 1;
                position: relative;
                cursor: default;
            }
            @media (max-width: 768px) {
                .number__card {
                    padding: 0;
                    margin-bottom: 1rem;
                }
            }
        `}</style></div>
    );
};

export const NumberBackgroundVideo = ({ video }) => {
    return (<>
        <video className="vid" autoPlay muted loop playsInline>
            <source src={video} type="video/webm"/>
        </video>
        <style jsx>{`
        .vid {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
        }
        @media (max-width: 768px) {
            .vid {
                max-width: 100%;
            }
        }
        `}</style></>);
};

export const NumberText = ({ children, teal = false }) => {
    return (
        <div className="number__number">
            {children}
            <style jsx>{`
            :global(.number__number):not(.number__title) {
                padding: 0;
                margin: 0;
                line-height: 1;
                display: block;
                width: 100%;
                text-align: left;
                color:var(--text);
                font-size: var(--font-size-6);
                padding-left: 2rem;
                position: relative;
            }
            :global(.number__number h2) {
                font-weight: var(--font-weight-bold);
            }
            :global(.number__number h2:not(.number__title):after) {
                content:'';
                position:absolute;
                bottom:0;
                top: 0;
                left:0;
                background: ${teal ? "var(--teal)" : "var(--pink)"};
                width: 3px;
                border-radius:10px 10px 10px 10px;
            }
            :global(.number__number .number__title) {
                color: var(--text);
                padding: 0;
                margin: 0;
                line-height: 1;
                display: block;
                width: 100%;
                font-size: var(--font-size-3);
            }
            .number__number {
                gap: 1rem;
                display: flex;
                flex-direction: column;
            }
            @media (max-width: 768px) {
                .number__number {
                    font-size: var(--font-size-5);
                    padding: 1rem;
                    text-align: center;
                }
                :global(.number__number h2:not(.number__title):after) {
                    display: none;
                }
            }
        `}</style>
        </div>
    );
};

export const NumberCardTitle = ({ children }) => {
    return (
        <div className="number__title">
            <p>{children}</p>
            <style jsx>{`
                p {
                    margin-top: 0;
                    font-size: var(--font-size-4);
                    line-height: var(--line-height-4);
                }
                .number__title {
                    color: var(--black-light);
                }
                :global(.dark .number__title) {
                    color: var(--text);
                }
            @media (max-width: 768px) {
                .number__title {
                    font-size: var(--font-size-2);
                }
            }
        `}</style>
        </div>
    );
};

export const NumberBadge = ({ children }) => {
    return (
        <div className="number__badge">
            {children}
        </div>
    );
};

export const NumberContent = ({ children }) => {
    return (<>
        <div className="number__content">
            {children}
        </div>
        <style jsx>{`
            .number__content {
                display: flex;
                flex-direction: row-reverse;
                gap: calc(var(--landing-page-gap) * 2);
                width: 100%;
            }
            @media (max-width: 1000px) {
                .number__content {
                    flex-wrap: wrap;
                    gap: 1rem;
                }
            }
            @media (max-width: 768px) {
                .number__content {
                    flex-direction: column;
                    gap: 1rem;
                }
        `}</style></>);
};

export const NumberCtas = ({ children }) => {
    return (
        <><div className="number__ctas">
            {children}
        </div><style jsx>{`
        :global(.number__cta:nth-child(2) button) {
            background-color: white;
            color: var(--pink);
            border: 1px solid var(--pink);
        }
        :global(.number__cta:nth-child(2) button):hover {
            background-color: var(--pink);
            color: #fff;
            border: 1px solid var(--pink);
        }
        .number__ctas {
            gap: 1rem;
            display: flex;
            margin-top: 1rem;
        }
    `}</style></>
    );
};

export const NumberCTA = ({ children }) => {
    return (
        <div className="number__cta">{children}</div>
    );
};